import { BACKEND_URL } from "src/constants";
import {
  CheckoutSessionDetails,
  SubscriptionDetails,
} from "src/types/subscription";
import axios from "src/utils/axios";

export interface SubscriptionAPIType {
  getSubscriptionDetails: (companyId) => Promise<SubscriptionDetails>;
  getSubscriptionPortalUrl: (companyId) => Promise<string>;
  getSubscriptionCheckoutSessionId: (
    companyId,
    priceId
  ) => Promise<CheckoutSessionDetails>;
}

export default function SubscriptionAPI(getUserToken): SubscriptionAPIType {
  return {
    getSubscriptionDetails: async (companyId) => {
      const response = await axios.get<SubscriptionDetails>(
        BACKEND_URL + `/api/company/${companyId}/subscription`,
        {
          headers: {
            Authorization: `Bearer ${await getUserToken()}`,
          },
        }
      );
      return response.data;
    },
    getSubscriptionPortalUrl: async (companyId) => {
      const response = await axios.post<string>(
        BACKEND_URL + `/api/company/${companyId}/subscription/portal`,
        null,
        {
          headers: {
            Authorization: `Bearer ${await getUserToken()}`,
          },
        }
      );
      return response.data;
    },
    getSubscriptionCheckoutSessionId: async (companyId, priceId) => {
      const response = await axios.post<CheckoutSessionDetails>(
        BACKEND_URL + `/api/company/${companyId}/subscription/checkout`,
        { priceId },
        {
          headers: {
            Authorization: `Bearer ${await getUserToken()}`,
          },
        }
      );
      return response.data;
    },
  };
}
