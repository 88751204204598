import React from "react";
import type {FC} from "react";
import {Link as RouterLink} from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    makeStyles,
    SvgIcon, Select, MenuItem,
} from "@material-ui/core";
import {Menu as MenuIcon} from "react-feather";
import Logo from "src/components/Logo";
import {THEMES} from "src/constants";
import type {Theme} from "src/theme";
import Account from "./Account";
import Notifications from "./Notifications";
import Search from "./Search";
import Settings from "./Settings";
import {useDispatch, useSelector} from "src/store";
import {setCurrentLocationId} from 'src/slices/global'

interface TopBarProps {
    className?: string;
    onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.LIGHT
            ? {
                boxShadow: "none",
                backgroundColor: theme.palette.primary.main,
            }
            : {}),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                backgroundColor: theme.palette.background.default,
            }
            : {}),
    },
    toolbar: {
        minHeight: 64,
    },
    activeLocationDropDown: {
        color: '#fbfbfd'
    }
}));

const TopBar: FC<TopBarProps> = ({className, onMobileNavOpen, ...rest}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {companies, currentCompanyId, currentLocationId} = useSelector(state => state.global);
    const currentCompany = companies?.find(c => c.id === currentCompanyId);
    const currentLocation = currentCompany?.locations.find(l => l.id === currentLocationId);

    const handleLocationChange = (e) => {
        const newLocationId = e.target.value;

        dispatch(setCurrentLocationId(newLocationId));
    };

    return (
        <AppBar className={clsx(classes.root, className)} {...rest}>
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <SvgIcon fontSize="small">
                            <MenuIcon/>
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <RouterLink to="/">
                        <Logo/>
                    </RouterLink>
                </Hidden>
                <Box ml={2} flexGrow={1}/>
                {currentLocation  && <Select
                    className={classes.activeLocationDropDown}
                    value={currentLocation.id}
                    label={currentLocation.address}
                    onChange={handleLocationChange}
                >
                    {currentCompany?.locations.map(location => <MenuItem key={location.id} value={location.id}>{location.address}</MenuItem>)}
                </Select>}
                <Search/>
                <Notifications/>
                <Settings/>
                <Box ml={2}>
                    <Account/>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
    onMobileNavOpen: () => {
    },
};

export default TopBar;
