import { BACKEND_URL } from "src/constants";
import axios from "src/utils/axios";

interface Product {}
export interface StripeAPIType {
  pay: (companyId, productId) => Promise<{ sessionId: string }>;
}

export default function StripeAPI(getUserToken): StripeAPIType {
  return {
    pay: async (companyId, priceId) => {
      const response = await axios.post<{ sessionId: string }>(
        BACKEND_URL + `/api/company/${companyId}/subscription/checkout`,
        { priceId },
        {
          headers: {
            Authorization: `Bearer ${await getUserToken()}`,
          },
        }
      );
      return response.data;
    },
  };
}
