import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Company, Location } from "src/types/company";

interface GlobalState {
  companies: Company[];
  currentCompanyId: string;
  currentLocationId: string;
}

const initialState: GlobalState = {
  companies: [],
  currentCompanyId: null,
  currentLocationId: null,
};

const slice = createSlice({
  name: "review_feed",
  initialState,
  reducers: {
    setCompanies(state: GlobalState, action: PayloadAction<Company[]>) {
      state.companies = action.payload;

      state.currentCompanyId =
        state.currentCompanyId || state.companies?.[0]?.id;

      state.currentLocationId =
        state.currentLocationId || state.companies?.[0]?.locations?.[0]?.id;
    },
    setCompanyLocations(
      state: GlobalState,
      action: PayloadAction<{ companyId: string; locations: Location[] }>
    ) {
      const company = state.companies.find(
        (company) => company.id === action.payload.companyId
      );
      company.locations = action.payload.locations;
    },

    addCompany(state: GlobalState, action: PayloadAction<Company>) {
      const newCompany = action.payload;
      state.companies.push(newCompany);
    },
    removeCompany(state: GlobalState, action: PayloadAction<string>) {
      const companyId = action.payload;
      state.companies = state.companies.filter(
        (company) => company.id === companyId
      );
    },
    addCompanyLocation(
      state: GlobalState,
      action: PayloadAction<{ companyId: string; location: Location }>
    ) {
      const company = state.companies.find(
        (company) => company.id === action.payload.companyId
      );
      company?.locations.push(action.payload.location);
    },
    removeCompanyLocation(
      state: GlobalState,
      action: PayloadAction<{ companyId: string; locationId: string }>
    ) {
      const company = state.companies.find(
        (company) => company.id === action.payload.companyId
      );
      company.locations = company?.locations.filter(
        (location) => location.id === action.payload.locationId
      );
    },
    setCurrentCompanyId(state: GlobalState, action: PayloadAction<string>) {
      const companyId = action.payload;

      state.currentCompanyId = companyId;
    },
    setCurrentLocationId(state: GlobalState, action: PayloadAction<string>) {
      const locationId = action.payload;

      state.currentLocationId = locationId;
    },
  },
});

export const {
  setCompanies,
  setCompanyLocations,
  addCompany,
  removeCompany,
  addCompanyLocation,
  removeCompanyLocation,
  setCurrentCompanyId,
  setCurrentLocationId,
} = slice.actions;

export const reducer = slice.reducer;

export default slice;
