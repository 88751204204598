import { BACKEND_URL } from "src/constants";
import { Label, ReviewRequest } from "src/types/requests";
import axios from "src/utils/axios";
import APIResult from "src/contexts/APIContext/APIResult";

export interface RequestsAPIType {
  getLabels: (companyId, locationId) => Promise<Label[]>;
  getRequests: (
    companyId,
    locationId,
    params: {},
    page?,
    itemsPerPage?
  ) => Promise<ReviewRequest[]>;
  getRequest: (companyId, locationId, requestId) => Promise<ReviewRequest>;
  sendRequest: (companyId, locationId, recipients, msg) => Promise<APIResult>;
}

export default function RequestsAPI(getUserToken): RequestsAPIType {
  return {
    getLabels: async (companyId, locationId) => {
      const response = await axios.get<Label[]>(
        BACKEND_URL +
          `/api/company/${companyId}/location/${locationId}/requests/labels`,
        {
          headers: {
            Authorization: `Bearer ${await getUserToken()}`,
          },
        }
      );
      return response.data;
    },
    getRequests: async (companyId, locationId, params, page, itemsPerPage) => {
      const response = await axios.get<ReviewRequest[]>(
        BACKEND_URL +
          `/api/company/${companyId}/location/${locationId}/requests`,
        {
          params: { ...params, page, itemsPerPage },
          headers: {
            Authorization: `Bearer ${await getUserToken()}`,
          },
        }
      );
      return response.data;
    },
    getRequest: async (companyId, locationId, requestId) => {
      const response = await axios.get<ReviewRequest>(
        BACKEND_URL +
          `/api/company/${companyId}/location/${locationId}/requests/${requestId}`,
        {
          headers: {
            Authorization: `Bearer ${await getUserToken()}`,
          },
        }
      );
      return response.data;
    },
    sendRequest: async (companyId, locationId, recipientIds, msg) => {
      const response = await axios.post<APIResult>(
        BACKEND_URL +
          `/api/company/${companyId}/location/${locationId}/requests/send`,
        { recipients: recipientIds, msg },
        {
          headers: {
            Authorization: `Bearer ${await getUserToken()}`,
          },
        }
      );
      return response.data;
    },
  };
}
