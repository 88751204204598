import {BACKEND_URL} from "src/constants";
import {Review, ReviewReply} from "src/types/review_feed";
import axios from "src/utils/axios";
import APIResult from "src/contexts/APIContext/APIResult";

export interface ReviewsAPIType {
    getReviews: (
        companyId,
        locationId,
        item?
    ) => Promise<Review[]>;
    getReview: (companyId, locationId, reviewId) => Promise<Review>;
    addReviewReply: (
        companyId,
        locationId,
        reviewId,
        text
    ) => Promise<ReviewReply>;
    updateReviewReply: (
        companyId,
        locationId,
        reviewId,
        replyId,
        text
    ) => Promise<ReviewReply>;
    deleteReviewReply: (companyId, locationId, reviewId) => Promise<APIResult>;
}

export default function ReviewsAPI(getUserToken): ReviewsAPIType {
    return {
        getReviews: async (companyId, locationId, item?) => {
            if (companyId === 'hotelhotels') {
                const response = await axios.get<Review[]>(
                    BACKEND_URL +
                    `/reviews/hotel`,
                    {
                        params: {
                            'item':'item',
                        },
                        headers: {
                            Authorization: `Bearer ${await getUserToken()}`,
                        },
                    }
                );
                return response.data;
            }

            const response = await axios.get<Review[]>(
                BACKEND_URL +
                `/api/company/${companyId}/location/${locationId}/reviews`,
                {
                    params: {
                        item,
                    },
                    headers: {
                        Authorization: `Bearer ${await getUserToken()}`,
                    },
                }
            );
            return response.data;
        },
        getReview: async (companyId, locationId) => {
            const response = await axios.get<Review>(
                BACKEND_URL +
                `/api/company/${companyId}/location/${locationId}/reviews`,
                {
                    headers: {
                        Authorization: `Bearer ${await getUserToken()}`,
                    },
                }
            );
            return response.data;
        },
        addReviewReply: async (companyId, locationId, reviewId, text) => {
            const response = await axios.post<ReviewReply>(
                BACKEND_URL +
                `/api/company/${companyId}/location/${locationId}/reviews/${reviewId}/reply`,
                {text},
                {
                    headers: {
                        Authorization: `Bearer ${await getUserToken()}`,
                    },
                }
            );
            return response.data;
        },
        updateReviewReply: async (
            companyId,
            locationId,
            reviewId,
            replyId,
            text
        ) => {
            const response = await axios.put<ReviewReply>(
                BACKEND_URL +
                `/api/company/${companyId}/location/${locationId}/reviews/${reviewId}/reply/${replyId}`,
                {text},
                {
                    headers: {
                        Authorization: `Bearer ${await getUserToken()}`,
                    },
                }
            );
            return response.data;
        },
        deleteReviewReply: async (companyId, locationId, reviewId) => {
            const response = await axios.delete<APIResult>(
                BACKEND_URL +
                `/api/company/${companyId}/location/${locationId}/reviews/${reviewId}/reply`,
                {
                    params: {currentLocationId: locationId, reviewId},
                    headers: {
                        Authorization: `Bearer ${await getUserToken()}`,
                    },
                }
            );
            return response.data;
        },
    };
}
