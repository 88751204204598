import { BACKEND_URL } from "src/constants";
import { Customer } from "src/types/customer";
import axios from "src/utils/axios";
import APIResult from "src/contexts/APIContext/APIResult";

export interface CustomerAPIType {
  getCustomers: (
    companyId,
    locationId,
    page?,
    itemsPerPage?
  ) => Promise<Customer[]>;
  getCustomer: (companyId, locationId, customerId) => Promise<Customer>;
  addCustomer: (companyId, locationId, newCustomer) => Promise<Customer>;
  updateCustomer: (
    companyId,
    locationId,
    customerId,
    newCustomer
  ) => Promise<Customer>;
  deleteCustomer: (companyId, locationId, customerId) => Promise<APIResult>;
}

export default function CustomerAPI(getUserToken): CustomerAPIType {
  return {
    getCustomers: async (companyId, locationId, page?, itemsPerPage?) => {
      const response = await axios.get<Customer[]>(
        BACKEND_URL +
          `/api/company/${companyId}/location/${locationId}/customers`,
        {
          params: { page, itemsPerPage },
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    getCustomer: async (companyId, locationId, customerId) => {
      const response = await axios.get<Customer>(
        BACKEND_URL +
          `/api/company/${companyId}/location/${locationId}/customers/${customerId}`,
        {
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    addCustomer: async (companyId, locationId, newCustomer) => {
      const response = await axios.post<Customer>(
        BACKEND_URL +
          `/api/company/${companyId}/location/${locationId}/customers`,
        newCustomer,
        {
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    updateCustomer: async (companyId, locationId, customerId, newCustomer) => {
      const response = await axios.put<Customer>(
        BACKEND_URL +
          `/api/company/${companyId}/location/${locationId}/customers/${customerId}`,
        newCustomer,
        {
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    deleteCustomer: async (companyId, locationId, customerId) => {
      const response = await axios.delete<APIResult>(
        BACKEND_URL +
          `/api/company/${companyId}/location/${locationId}/customers/${customerId}`,
        {
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
  };
}
