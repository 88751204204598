import React from "react";
import type { FC, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "src/hooks/useAuth";
import { useSelector } from "src/store";

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { currentCompanyId, currentLocationId } = useSelector(
    (state) => state.global
  );

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (isAuthenticated && (!currentCompanyId || !currentLocationId)) {
    return <Redirect to="/app/company/create" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
