import { combineReducers } from "@reduxjs/toolkit";
import { reducer as globalReducer } from "src/slices/global";
import { reducer as calendarReducer } from "src/slices/calendar";
import { reducer as chatReducer } from "src/slices/chat";
import { reducer as formReducer } from "redux-form";
import { reducer as kanbanReducer } from "src/slices/kanban";
import { reducer as mailReducer } from "src/slices/mail";
import { reducer as notificationReducer } from "src/slices/notification";
import { reducer as reviewsReducer } from "src/slices/reviews";
import { reducer as requestsReducer } from "src/slices/requests";

const rootReducer = combineReducers({
  global: globalReducer,
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  reviews: reviewsReducer,
  requests: requestsReducer,
});

export default rootReducer;
