export const auth0Config = {
  client_id: "5RbSVYI8rFGq7J9WRVOyPI8tfZ65NAki",
  domain: "revu.us.auth0.com",
  cacheLocation: "localstorage" as "localstorage",
  useRefreshTokens: true,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const googleAPIDashboardConfig = {
  GMB_CLIENT_ID:
    "87491657805-h59i9cr146586rg9l70edbqisrvqkg80.apps.googleusercontent.com",
};

export const stripeAPIConfig = {
  apiKey:
    "pk_test_51IJkNCI8pqZPUs045JdKzGAwj1jZDsMXsSX5rjn9DtHLkPTdmr4wsDXiowLwXontI4zKO4W1xkKJppvR8aaKjEmo00u0kzE10v",
};
