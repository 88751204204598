import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { AppThunk } from "src/store";
import { Customer } from "src/types/customer";
import type { Label, ReviewRequest } from "src/types/requests";
import objFromArray from "src/utils/objFromArray";

interface RequestsState {
  requests: {
    byId: Record<string, ReviewRequest>;
    allIds: string[];
  };
  recipients: Customer[];
  labels: Label[];
  isSidebarOpen: boolean;
  isComposeOpen: boolean;
}

const initialState: RequestsState = {
  requests: {
    byId: {},
    allIds: [],
  },
  recipients: [],
  labels: [],
  isSidebarOpen: false,
  isComposeOpen: false,
};

const slice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setLabels(
      state: RequestsState,
      action: PayloadAction<{ labels: Label[] }>
    ) {
      const { labels } = action.payload;
      state.labels = labels;
    },
    setRequests(
      state: RequestsState,
      action: PayloadAction<{ requests: ReviewRequest[] }>
    ) {
      const { requests } = action.payload;

      state.requests.byId = objFromArray(requests);
      state.requests.allIds = Object.keys(state.requests.byId);
    },
    setRequest(
      state: RequestsState,
      action: PayloadAction<{ request: ReviewRequest }>
    ) {
      const { request } = action.payload;

      state.requests.byId[request.id] = request;

      if (!state.requests.allIds.includes(request.id)) {
        state.requests.allIds.push(request.id);
      }
    },
    openSidebar(state: RequestsState) {
      state.isSidebarOpen = true;
    },
    closeSidebar(state: RequestsState) {
      state.isSidebarOpen = false;
    },
    openCompose(
      state: RequestsState,
      action: PayloadAction<{ recipients: Customer[] }>
    ) {
      state.isComposeOpen = true;
      state.recipients = action.payload.recipients;
    },
    closeCompose(state: RequestsState) {
      state.isComposeOpen = false;
    },
  },
});

export const reducer = slice.reducer;
export const { setLabels, setRequests, setRequest } = slice.actions;

export const openSidebar = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openSidebar());
};

export const closeSidebar = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeSidebar());
};

export const openCompose = (recipients?: Customer[]): AppThunk => async (
  dispatch
) => {
  dispatch(slice.actions.openCompose({ recipients }));
};

export const closeCompose = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeCompose());
};

export default slice;
