export const APP_VERSION = "3.1.0";

export const ENABLE_REDUX_DEV_TOOLS = true;

export const BACKEND_URL = "https://revu-api.chasevanbuskirk.com";

export const FRONTEND_URL = "https://revu.chasevanbuskirk.com";

export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
  UNICORN: "UNICORN",
};
