import React, { createContext, FC, ReactNode, useState } from "react";
import useAuth from "src/hooks/useAuth";
import CompanyAPI, { CompanyAPIType } from "src/apis/CompanyAPI";
import CustomerAPI, { CustomerAPIType } from "src/apis/CustomerAPI";
import RequestsAPI, { RequestsAPIType } from "src/apis/RequestsAPI";
import ReviewsAPI, { ReviewsAPIType } from "src/apis/ReviewsAPI";
import StripeAPI, { StripeAPIType } from "src/apis/StripeAPI";
import SubscriptionAPI, { SubscriptionAPIType } from "src/apis/SubscriptionAPI";

interface APIState {
  customerAPI: CustomerAPIType;
  requestsAPI: RequestsAPIType;
  reviewsAPI: ReviewsAPIType;
  companyAPI: CompanyAPIType;
  stripeAPI: StripeAPIType;
  subscriptionAPI: SubscriptionAPIType;
}

interface APIProviderProps {
  children: ReactNode;
}

const initialAPIState: APIState = {
  customerAPI: null,
  requestsAPI: null,
  reviewsAPI: null,
  companyAPI: null,
  stripeAPI: null,
  subscriptionAPI: null,
};

const APIContext = createContext<APIState>({
  ...initialAPIState,
});

export const APIProvider: FC<APIProviderProps> = ({ children }) => {
  const { getUserToken } = useAuth();

  const [state] = useState({
    customerAPI: CustomerAPI(getUserToken),
    requestsAPI: RequestsAPI(getUserToken),
    reviewsAPI: ReviewsAPI(getUserToken),
    companyAPI: CompanyAPI(getUserToken),
    stripeAPI: StripeAPI(getUserToken),
    subscriptionAPI: SubscriptionAPI(getUserToken),
  });

  return <APIContext.Provider value={state}>{children}</APIContext.Provider>;
};

export default APIContext;
