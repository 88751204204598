import { BACKEND_URL } from "src/constants";
import { Company, Location } from "src/types/company";
import { ShortlinkSettings } from "src/types/shortlink_settings";
import axios from "src/utils/axios";
import APIResult from "src/contexts/APIContext/APIResult";
import { Shortlink } from "src/types/shortlink";
import { Feedback } from "src/types/feedback";

export interface CompanyAPIType {
  getCompanies: (page?, itemsPerPage?) => Promise<Company[]>;
  getCompany: (companyId) => Promise<Company>;
  addCompany: (companyName) => Promise<Company>;
  updateCompany: (companyId, newCompany) => Promise<Company>;
  deleteCompany: (companyId) => Promise<APIResult>;
  getCompanyLocations: (companyId, page?, itemsPerPage?) => Promise<Location[]>;
  addCompanyLocation: (companyId, newLocation) => Promise<Location>;
  updateCompanyLocation: (
    companyId,
    locationId,
    newLocation
  ) => Promise<Location>;
  deleteCompanyLocation: (companyId, locationId) => Promise<APIResult>;
  sendPlatformAuthInfo: (
    companyId,
    locationId,
    platform,
    code,
    userId
  ) => Promise<APIResult>;
  getShortlinkSettings: (companyId, locationId) => Promise<ShortlinkSettings>;
  updateShortlinkSettings: (
    companyId,
    locationId,
    settings: ShortlinkSettings
  ) => Promise<ShortlinkSettings>;
  getShortlinkData: (shortlinkId, shortlinkHash) => Promise<Shortlink>;
  submitPrivateFeedback: (
    shortlinkId,
    shortlinkHash,
    feedback
  ) => Promise<APIResult>;
  getPrivateFeedback: (companyId, locationId, item?) => Promise<Feedback[]>;
}

export default function CompanyAPI(getUserToken): CompanyAPIType {
  return {
    getCompanies: async (page?, itemsPerPage?) => {
      const response = await axios.get<Company[]>(
        BACKEND_URL + "/api/company",
        {
          params: { page, itemsPerPage },
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    getCompany: async (companyId) => {
      const response = await axios.get<Company>(
        BACKEND_URL + `/api/company/${companyId}`,
        {
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    addCompany: async (companyName) => {
      const response = await axios.post<Company>(
        BACKEND_URL + "/api/company",
        { name: companyName },
        {
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    updateCompany: async (companyId, newCompany) => {
      const response = await axios.put<Company>(
        BACKEND_URL + `/api/company/${companyId}`,
        newCompany,
        {
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    deleteCompany: async (companyId) => {
      const response = await axios.delete<APIResult>(
        BACKEND_URL + `/api/company/${companyId}`,
        {
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    getCompanyLocations: async (companyId) => {
      const response = await axios.get<Location[]>(
        BACKEND_URL + `/api/company/${companyId}/location`,
        {
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    addCompanyLocation: async (companyId, newLocation) => {
      const response = await axios.post<Location>(
        BACKEND_URL + `/api/company/${companyId}/location`,
        { ...newLocation },
        {
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    updateCompanyLocation: async (companyId, locationId, newLocation) => {
      const response = await axios.put<Location>(
        BACKEND_URL + `/api/company/${companyId}/location/${locationId}`,
        { ...newLocation },
        {
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    deleteCompanyLocation: async (companyId, locationId) => {
      const response = await axios.delete<APIResult>(
        BACKEND_URL + `/api/company/${companyId}/location/${locationId}`,
        {
          headers: { Authorization: `Bearer ${await getUserToken()}` },
        }
      );
      return response.data;
    },
    sendPlatformAuthInfo: async (
      companyId,
      locationId,
      platform,
      token,
      userId
    ) => {
      const response = await axios.put<APIResult>(
        BACKEND_URL + `/api/company/${companyId}/location/${locationId}/token`,
        {
          platform,
          token,
          userId,
        },
        {
          headers: {
            Authorization: `Bearer ${await getUserToken()}`,
          },
        }
      );

      return response.data;
    },
    getShortlinkSettings: async (companyId, locationId) => {
      const response = await axios.get<ShortlinkSettings>(
        BACKEND_URL +
          `/api/company/${companyId}/location/${locationId}/shortlink`,
        {
          headers: {
            Authorization: `Bearer ${await getUserToken()}`,
          },
        }
      );

      return response.data;
    },
    updateShortlinkSettings: async (companyId, locationId, newSettings) => {
      const response = await axios.put<ShortlinkSettings>(
        BACKEND_URL +
          `/api/company/${companyId}/location/${locationId}/shortlink`,
        newSettings,
        {
          headers: {
            Authorization: `Bearer ${await getUserToken()}`,
          },
        }
      );

      return response.data;
    },
    getShortlinkData: async (shortlinkId, shortlinkHash) => {
      const response = await axios.get<Shortlink>(
        BACKEND_URL + "/api/shortlink/" + shortlinkId + "/" + shortlinkHash
      );
      return response.data;
    },
    submitPrivateFeedback: async (shortlinkId, shortlinkHash, feedback) => {
      const response = await axios.post<APIResult>(
        BACKEND_URL +
          "/api/shortlink/" +
          shortlinkId +
          "/" +
          shortlinkHash +
          "/feedback",
        { feedback }
      );
      return response.data;
    },
    getPrivateFeedback: async (companyId, locationId, item?) => {
      const response = await axios.get<Feedback[]>(
        BACKEND_URL +
          `/api/company/${companyId}/location/${locationId}/reviews/private`,
        {
          params: {
            item,
          },
          headers: {
            Authorization: `Bearer ${await getUserToken()}`,
          },
        }
      );
      return response.data;
    },
  };
}
