import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Feedback } from "src/types/feedback";
import { Review, ReviewReply } from "src/types/review_feed";

interface ReviewsState {
  reviews: Review[];
  feedback: Feedback[];
}

const initialState: ReviewsState = {
  reviews: [],
  feedback: [],
};

const slice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    setReviews(
      state: ReviewsState,
      action: PayloadAction<{ reviews: Review[] }>
    ) {
      const { reviews } = action.payload;

      state.reviews = reviews;
    },
    setReviewReply(
      state: ReviewsState,
      action: PayloadAction<{
        reviewId: string;
        replyId: string;
        comment: string;
      }>
    ) {
      const { reviewId, comment, replyId } = action.payload;

      state.reviews
        .find((el) => el.id === reviewId)
        .replies.find((r) => r.id === replyId).text = comment;
    },
    addReviewReply(
      state: ReviewsState,
      action: PayloadAction<{
        reviewId: string;
        reviewReply: ReviewReply;
      }>
    ) {
      const { reviewId, reviewReply } = action.payload;

      state.reviews.find((el) => el.id === reviewId).replies.push(reviewReply);
    },
    updateReviewReply(
      state: ReviewsState,
      action: PayloadAction<{
        reviewId: string;
        replyId: string;
        reviewReply: ReviewReply;
      }>
    ) {
      const { reviewId, replyId, reviewReply } = action.payload;
      const review = state.reviews.find((r) => r.id === reviewId);
      const replyIdx = review.replies.findIndex((r) => r.id === replyId);
      review.replies[replyIdx] = reviewReply;
    },
    setPrivateFeedback(
      state: ReviewsState,
      action: PayloadAction<{ feedback: Feedback[] }>
    ) {
      const { feedback } = action.payload;

      state.feedback = feedback;
    },
  },
});

export const reducer = slice.reducer;
export const {
  setReviews,
  setReviewReply,
  addReviewReply,
  updateReviewReply,
  setPrivateFeedback
} = slice.actions;

export default slice;
