import { Auth0Client } from "@auth0/auth0-spa-js";
import { FC, ReactNode, useState } from "react";
import React, { createContext, useEffect, useReducer } from "react";
import SplashScreen from "src/components/SplashScreen";
import { auth0Config } from "src/config";
import { setCompanies } from "src/slices/global";
import { useDispatch } from "src/store";
import type { User } from "src/types/user";
import CompanyAPI from "src/apis/CompanyAPI";
import { FRONTEND_URL } from "src/constants";

let auth0Client: Auth0Client | null = null;

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export interface AuthContextValue extends AuthState {
  method: "Auth0";
  loginWithPopup: (options?: any) => Promise<void>;
  logout: () => void;
  getUserToken: () => Promise<string>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: "INITIALISE";
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type RegisterAction = {
  type: "REGISTER";
};

type Action = InitialiseAction | LoginAction | LogoutAction | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: "Auth0",
  loginWithPopup: () => Promise.resolve(),
  logout: () => {},
  getUserToken: () => Promise.resolve(""),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const globalDispatch = useDispatch();
  const getUserToken = async () => {
    await auth0Client.getTokenSilently();
    const claims = await auth0Client.getIdTokenClaims();
    return claims.__raw;
  };
  const [companyAPI] = useState(CompanyAPI(getUserToken));

  const loginWithPopup = async (options?: any) => {
    await auth0Client.loginWithPopup(options);

    const isAuthenticated = await auth0Client.isAuthenticated();
    if (isAuthenticated) {
      const user = await auth0Client.getUser();

      const companies = await companyAPI.getCompanies();
      await globalDispatch(setCompanies(companies));

      dispatch({
        type: "LOGIN",
        payload: {
          user: {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: user.name,
            tier: "Premium",
          },
        },
      });
    }
  };

  const logout = () => {
    auth0Client.logout({
      returnTo: FRONTEND_URL, // TODO: Change to constant?
    });

    dispatch({
      type: "LOGOUT",
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: window.location.origin,
          ...auth0Config,
        });

        await auth0Client.checkSession();

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          // This is triggered at every authentication (i.e. every page refresh)
          const user = await auth0Client.getUser();
          //const claims = await auth0Client.getIdTokenClaims();

          const companies = await companyAPI.getCompanies();
          await globalDispatch(setCompanies(companies));

          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated,
              user: {
                id: user.sub,
                avatar: user.picture,
                email: user.email,
                name: user.name,
                tier: "Premium",
              },
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, [globalDispatch, companyAPI]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "Auth0",
        loginWithPopup,
        logout,
        getUserToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
